import {
    AssetVersionType,
    Form,
    Meta,
    Organization,
    PaginationLink,
    Session,
    UserData,
} from './utils';

export type EncodingOptionsPayload = { clip_start_time?: number };

export interface SqlUser {
    uuid: string;
    user_name: string;
    password: string;
}

export type MovementChartData = Array<{
    data: Array<{
        date: string;
        value: string;
        unit: string;
        skipped_percent: number;
        complete_percent: number;
        full_date: string;
        measurement: string;
        metric: AggregateMetricChoice;
    }>;
    name: string;
}>;

export type MovementMetricChoice =
    | 'distance_value'
    | 'time_value'
    | 'load_value'
    | 'rest_value'
    | 'range_of_motion_value'
    | 'speed_value'
    | 'force_value'
    | 'rpe_value'
    | 'rsi_value'
    | 'acceleration_value'
    | 'power_value'
    | 'reps';

export type AggregateMetricChoice = 'avg' | 'count' | 'sum' | 'min' | 'max';

export interface MovementMetricFilter {
    user_key: number[];
    movement_key: number;
    starts_after: number;
    ends_before: number;
    measurement: AggregateMetricChoice;
    metric: MovementMetricChoice;
    order_direction: 'asc' | 'desc';
    group_by: 'day' | 'hour';
}

export const AGGREGATE_CHOICES: Array<{ value: AggregateMetricChoice; label: string }> = [
    {
        value: 'sum',
        label: 'Sum',
    },
    {
        value: 'avg',
        label: 'Average',
    },
    {
        value: 'max',
        label: 'Maximum',
    },
    {
        value: 'min',
        label: 'Minimum',
    },
    {
        value: 'count',
        label: 'Count',
    },
];

export const METRIC_CHOICES: Array<{ value: MovementMetricChoice; label: string }> = [
    {
        value: 'distance_value',
        label: 'Distance',
    },
    {
        value: 'time_value',
        label: 'Time',
    },
    {
        value: 'load_value',
        label: 'Load',
    },
    {
        value: 'rest_value',
        label: 'Rest',
    },
    {
        value: 'range_of_motion_value',
        label: 'Range Of Motion',
    },
    {
        value: 'speed_value',
        label: 'Speed',
    },
    {
        value: 'force_value',
        label: 'Force',
    },
    {
        value: 'rpe_value',
        label: 'RPE',
    },
    {
        value: 'rsi_value',
        label: 'RSI',
    },
    {
        value: 'reps',
        label: 'Reps',
    },
    {
        value: 'acceleration_value',
        label: 'Acceleration',
    },
    {
        value: 'power_value',
        label: 'Power',
    },
];

export interface MediaRendition {
    uuid: string;
    media_uuid: string;
    url: string;
    mime_type: string;
    type: AssetVersionType;
    size: number;
    width: number;
    height: number;
    duration: number;
}

export interface Media {
    uuid: string;
    url: string;
    cover_url: string;
    mime_type: string;
    name: string;
    path: string;
    renditions: MediaRendition[];
    organization: Organization;
    category: string;
    created_at: number;
    size: number;
    status: string;
    description: null | string;
}

export interface SimplePaginateResponse<T> {
    data: Array<T>;
    links: PaginationLink;
    meta: Meta;
}

export interface Integration {
    api_type: string;
}

export interface AthleteSubscription {
    id: string;
    user_uuid: string;
    pod_uuid: string | null;
    name: 'default' | 'athlete_fiyrpod' | 'athlete_combo';
    stripe_price: string;
    stripe_status:
        | 'active'
        | 'canceled'
        | 'incomplete'
        | 'incomplete_expired'
        | 'past_due'
        | 'trialing'
        | 'unpaid'
        | 'paused';
}

export interface AthletePod {
    uuid: string;
    serial_number: string;
    simple_id: string;
    club_id: string | null;
    aws_thing_id: string;
    organization_uuid: string;
    organization_name: string;
    athlete_name: string | null;
    transfer_request_sent_at: string;
    transfer_request_accepted_at: string | null;
    created_at: number;
}

export interface Subscription {
    id: string;
    organization_uuid: string;
    organization_name?: string;
    name: 'default' | 'sql' | 'organization' | 'fiyrpod_organization';
    stripe_price: string;
    stripe_status:
        | 'active'
        | 'canceled'
        | 'incomplete'
        | 'incomplete_expired'
        | 'past_due'
        | 'trialing'
        | 'unpaid'
        | 'paused';
    quantity: number | undefined;
}

export interface SimplePaginationResponse<T> {
    current_page: number;
    data: Array<T>;
    first_page_url: string;
    from: number;
    next_page_url: string | null;
    path: string;
    per_page: number;
    prev_page_url: string | null;
    to: number;
}

export type MarketplaceItem = {
    uuid: string;
    organization_uuid: string;
    marketplaceable_type: 'form' | 'session';
    marketplaceable: Form | Session;
    organization?: Organization;
    sales_type: 'business_to_business' | 'business_to_consumer';
    user?: UserData;
    sales?: number;
    price: number;
    resell_status: 'enabled' | 'disabled' | 'pending';
    created_at: number;
    updated_at: number;
};

export interface MovementFilter {
    movement_key: number;
    name: string;
}

export interface Tag {
    id: string;
    type: TagType;
    slug: string;
    name: string;
    organization_uuid: string;
}

export type TagType = 'uploads' | 'reports' | 'position' | 'position_group' | 'sprints';

export interface RawDataPointMetrics {
    maxVelocity: number;
    timeOfMaxVelocity: number;
    totalDistance: number;
    distanceFromStartToMaxVelocity: number;
    distanceFromMaxVelocityToEnd: number;
    timeFromMaxVelocityToEnd: number;
}

export type DateRangeValue = [string, string] | string;

export type StripeAccount = {
    id: string;
    object: 'account';
    business_profile: {
        mcc: string;
        name: string;
        product_description: string;
        support_address: {
            city: string;
        };
    };
    capabilities: {
        card_payments: 'active' | 'inactive' | 'pending';
        transfers: 'active' | 'inactive' | 'pending';
    };
    charges_enabled: boolean;
    country: string;
    created: number;
    default_currency: string;
    details_submitted: boolean;
    email: string;
    payouts_enabled: boolean;
    requirements: {
        current_deadline: number | null;
        currently_due: string[];
        disabled_reason: string | null;
    };
};
