import CreditCardIcon from '@mui/icons-material/CreditCard';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { LoadingButton } from '@mui/lab';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormLabel,
    Grid,
    Hidden,
    IconButton,
    InputAdornment,
    Paper,
    Radio,
    RadioGroup,
    Snackbar,
    Stack,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme } from '@mui/system';
import {
    deleteOrganizationMedia,
    getSignedUrl,
    updateOrganization,
    uploadFile,
    viewOrganization,
} from 'api';
import { UpdateOrganizationPayload } from 'api/Organization/updateOrganization';
import { AxiosError } from 'axios';
import {
    ButtonWithLoader,
    SharedUsersAvatarGroup,
    SnackBar,
    TopBarWithShareIconLayout,
    UploadLogo,
} from 'components';
import OrganizationSharingDrawer from 'components/Navigation/OrganizationSharingDrawer';
import { Form, Formik } from 'formik';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DbImage from 'images/db_icon.png';
import SpaceShuttle from 'images/space-shuttle.png';
import SprintAnalysisLogo from 'images/SprintAnalysisLogo.png';
import TemLogo from 'images/TemLogo.svg';
import React, { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory, useParams } from 'react-router-dom';
import ROUTES from 'Routes/routes';
import { Organization, SharedUser, useToggle } from 'utils';
import * as yup from 'yup';
import createCheckoutSession from '../../api/Auth/createCheckoutSession';
import { createIntegration, getAllIntegrations } from '../../api/integrations.api';
import { getStripeExpressAccount } from '../../api/organizations.api';
import {
    isSubscribedToOrgAccess,
    isSubscribedToSqlAccess,
    isSubscribedToVideoAnalysis,
} from '../../app.functions';
import { Integration, StripeAccount } from '../../app.types';
import ChooseOrganizationPlanPopover from '../../components/Functional/ChooseOrganizationPlanPopover';
import { UploadBanner } from '../../components/Functional/UploadBanner';
import { UploadVideo } from '../../components/Functional/UploadVideo';
import { OrganizationsContext } from '../../contexts/OrganizationsContext';
import { ReactComponent as OfficeDeskIcon } from '../../images/office-desk.svg';
import { ReactComponent as StripeIcon } from '../../images/stripe.svg';
import MarketplaceSettingsPage from './MarketplaceSettingsPage';
import PodsPage from './PodsPage';
import SqlUsersList from './SqlUsersList';
import { Subscription } from 'app.types';
import viewStripeHistory from '../../api/Auth/viewStripeHistory';

export default function EditOrganization(): ReactElement {
    const { subscriptions } = useContext(OrganizationsContext);
    const [orgSubscription, setOrgSubscription] = useState<Subscription | null>(null);
    const [organization, setOrganization] = useState<Organization | null>(null);
    const [creating, setCreating] = useState(false);
    let { id } = useParams<{ id: string }>();
    const { push, goBack } = useHistory();
    const [uploadLogo, setUploadLogo] = useState<File>();
    const [uploadBanner, setUploadBanner] = useState<File>();
    const [uploadVideo, setUploadVideo] = useState<File>();
    const [tabValue, setTabValue] = useState(0);
    const [sharedUsers, setSharedUsers] = useState<Array<SharedUser>>([]);
    const [saving, setSaving] = useState(false);
    const [logoUploading] = useState(false);
    const [bannerUploading] = useState(false);
    const [videoUploading] = useState(false);
    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [aboutUs, setAboutUs] = useState('');
    const [zip, setZip] = useState('');
    const [notifyUserMessage, setNotifyUserMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [sharingOpen, toggleSharing] = useToggle();
    const [cookies, setCookie] = useCookies(['favOrg']);
    const [favOrg, setFavOrg] = useState(cookies.favOrg);
    const [temModal, setTemModal] = useState(false);
    const [sprintAnalysisModal, setSprintAnalysisModal] = useState(false);
    const [integrations, setIntegrations] = useState<Integration[]>([]);
    const [checkingOut, setCheckingOut] = useState(false);
    const theme = useTheme();
    const [stripeAccount, setStripeAccount] = React.useState<StripeAccount>();
    const [hasIntegratedStripe, setHasIntegratedStripe] = React.useState<boolean>(false);
    const [integrationsLoaded, setIntegrationsLoaded] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState<string | null>(null);
    const [hasStripe, setHasStripe] = React.useState(false);
    const [muxPlaybackId, setMuxPlaybackId] = useState<string | undefined>(undefined);
    const isSmallScreen = useMediaQuery(theme.breakpoints.down(1000));
    const [copySuccess, setCopySuccess] = useState(false);
    const baseUrl = process.env.REACT_APP_URL || 'https://1tul.com';
    const athleteGetStartedUrl = `${baseUrl}/athletes-start/${slug}`;
    const athleteMarketplaceUrl = `${baseUrl}/athletes/${slug}`;
    const orgMarketplaceUrl = `${baseUrl}/marketplace/${slug}`;

    const copyToClipboard = useCallback(
        (type: 'start' | 'athlete' | 'organization') => {
            let urlToCopy = athleteGetStartedUrl;

            if (type === 'athlete') {
                urlToCopy = athleteMarketplaceUrl;
            } else if (type === 'organization') {
                urlToCopy = orgMarketplaceUrl;
            }

            const fallbackCopyTextToClipboard = (text: string) => {
                const textArea = document.createElement('textarea');
                textArea.value = text;

                // Avoid scrolling to bottom
                textArea.style.top = '0';
                textArea.style.left = '0';
                textArea.style.position = 'fixed';

                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();

                try {
                    const successful = document.execCommand('copy');
                    if (successful) {
                        setCopySuccess(true);
                        setTimeout(() => setCopySuccess(false), 2000);
                    } else {
                        console.error('Fallback: Unable to copy');
                    }
                } catch (err) {
                    console.error('Fallback: Unable to copy', err);
                }

                document.body.removeChild(textArea);
            };

            if (!navigator.clipboard) {
                fallbackCopyTextToClipboard(urlToCopy);
                return;
            }
            navigator.clipboard.writeText(urlToCopy).then(
                () => {
                    setCopySuccess(true);
                    setTimeout(() => setCopySuccess(false), 2000);
                },
                (err) => {
                    console.error('Async: Could not copy text: ', err);
                    fallbackCopyTextToClipboard(urlToCopy);
                },
            );
        },
        [athleteGetStartedUrl, athleteMarketplaceUrl, orgMarketplaceUrl],
    );

    useEffect(() => {
        if (subscriptions) {
            setOrgSubscription(
                subscriptions.find(
                    (s) => s.name === 'organization' && s.stripe_status === 'active',
                ) ?? null,
            );
        }
    }, [subscriptions]);

    useEffect(() => {
        const fetchStripeAccountStatus = async () => {
            if (stripeAccount && hasIntegratedStripe && integrationsLoaded) {
                setHasStripe(true);
            }
        };

        fetchStripeAccountStatus();
    });

    useEffect(() => {
        const fetchIntegrations = async () => {
            if (organization) {
                // Ensure `organization` is not null before accessing its properties
                try {
                    const response = await getAllIntegrations(organization.uuid);
                    setIntegrations(response.data);
                    setIntegrationsLoaded(true);
                } catch (e) {
                    setSnackMessage(
                        'Error while loading required information to display this page',
                    );
                }
            }
        };

        fetchIntegrations();
    }, [organization]);

    const handleTabValueChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        console.log('tab value', newValue);
    };

    const setFav = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.checked) {
                setFavOrg(`${organization?.name}${organization?.uuid}`);
                setCookie(`favOrg`, `${organization?.name}${organization?.uuid}`, {
                    domain: '.1tul.com',
                    path: '/',
                });
            }
        },
        [organization?.name, organization?.uuid, setCookie],
    );

    const deleteVideo = async () => {
        try {
            await deleteOrganizationMedia(organization?.uuid, 'video');
        } catch (error) {
            console.error('Failed to delete video:', error);
        }
    };

    const deleteBanner = async () => {
        try {
            await deleteOrganizationMedia(organization?.uuid, 'banner');
        } catch (error) {
            console.error('Failed to delete banner:', error);
        }
    };

    const deleteLogo = async () => {
        try {
            await deleteOrganizationMedia(organization?.uuid, 'logo');
        } catch (error) {
            console.error('Failed to delete logo:', error);
        }
    };

    const handleSubmit = async (payload: UpdateOrganizationPayload) => {
        console.debug('handle submit');
        setSaving(true);

        const response = await updateOrganization(id, payload);
        setOrganization(response.data);
        if (response.data.mux_playback_id) {
            // Wait before setting the playbackId to avoid Mux race condition.
            setTimeout(() => {
                setMuxPlaybackId(response.data.mux_playback_id);
            }, 5000);
        }
        setSaving(false);
        return true;
    };

    const handleSave = async () => {
        setSaving(true);

        interface Payload {
            name: string;
            slug: string;
            zip: string;
            about_us: string;
            logo?: string;
            banner?: string;
            video?: string;
        }

        let payload: Payload = {
            name: title,
            slug: slug,
            zip: zip,
            about_us: aboutUs,
        };

        if (uploadLogo) {
            console.debug('upload logo');
            try {
                const response = await getSignedUrl();
                if (response.status === 201) {
                    const res = await uploadFile(response.data.url, uploadLogo);
                    if (res.status === 200) {
                        payload.logo = response.data.key;
                    }
                } else {
                    setSnackMessage('There was an error uploading the logo. Please try again.');
                    setSuccess(false);
                    return;
                }
            } catch (err: any) {
                const error: AxiosError<{ message: string }> = err;
                setSnackMessage(
                    `There was an error uploading the logo: ${
                        error.response?.data?.message ?? 'an unknown error occurred'
                    }`,
                );
                setSuccess(false);
                setSaving(false);
                return false;
            }
        }

        if (uploadBanner) {
            console.debug('upload banner');
            try {
                const response = await getSignedUrl();
                if (response.status === 201) {
                    const res = await uploadFile(response.data.url, uploadBanner);
                    if (res.status === 200) {
                        payload.banner = response.data.key;
                    }
                } else {
                    setSnackMessage('There was an error uploading the banner. Please try again.');
                    setSuccess(false);
                    return;
                }
            } catch (err: any) {
                const error: AxiosError<{ message: string }> = err;
                setSnackMessage(
                    `There was an error uploading the banner: ${
                        error.response?.data?.message ?? 'an unknown error occurred'
                    }`,
                );
                setSuccess(false);
                setSaving(false);
                return false;
            }
        }

        if (uploadVideo) {
            console.debug('upload video');
            try {
                const response = await getSignedUrl();
                if (response.status === 201) {
                    const res = await uploadFile(response.data.url, uploadVideo);
                    if (res.status === 200) {
                        payload.video = response.data.key;
                    }
                } else {
                    setSnackMessage('There was an error uploading the video. Please try again.');
                    setSuccess(false);
                    return;
                }
            } catch (err: any) {
                const error: AxiosError<{ message: string }> = err;
                setSnackMessage(
                    `There was an error uploading the video: ${
                        error.response?.data?.message ?? 'an unknown error occurred'
                    }`,
                );
                setSuccess(false);
                setSaving(false);
                return;
            }
        }

        try {
            console.debug('handleSave() payload', payload);
            await handleSubmit(payload);
            setSnackMessage('Organization updated successfully');
        } catch (err: any) {
            const error: AxiosError<{ message: string }> = err;
            setSnackMessage(
                `There was an error updating the organization: ${
                    error.response?.data?.message ?? 'an unknown error occurred'
                }`,
            );
            setSuccess(false);
            setSaving(false);
        }
    };

    useEffect(() => {
        if (organization?.uuid) {
            getAllIntegrations(organization?.uuid)
                .then((response) => {
                    setIntegrations(response.data);
                })
                .catch(() => {})
                .finally(() => {});
        }
    }, [organization]);

    const SaveButton = () => (
        <Grid item sx={{ width: '100%' }}>
            <ButtonWithLoader
                onClick={() => handleSave()}
                loading={saving}
                disabled={saving}
                variant="contained"
                color="primary"
                sx={{
                    width: '100%',
                    height: '50%',
                    marginTop: theme.spacing(6),
                    marginBottom: theme.spacing(24),
                }}
            >
                Save
            </ButtonWithLoader>
        </Grid>
    );
    useEffect(() => {
        viewOrganization(id)
            .then((response) => {
                setOrganization(response.data);
                if (response.data.mux_playback_id) {
                    setMuxPlaybackId(response.data.mux_playback_id);
                }
                setTitle(response.data.name);
                setSlug(response.data.slug);
                setAboutUs(response.data.about_us ?? '');
                setZip(response.data.zip ?? '');
            })
            .catch(() => {
                push(ROUTES.NotFound.path);
            })
            .finally();
    }, [id, push]);

    useEffect(() => {
        setHasIntegratedStripe(
            integrations.filter((i) => i.api_type === 'stripe_express').length > 0 ?? false,
        );
    }, [integrations]);

    useEffect(() => {
        const fetchStripeAccountStatus = async () => {
            if (organization) {
                try {
                    const response = await getStripeExpressAccount(organization.uuid);
                    setStripeAccount(response.data);
                } catch (e) {
                    setSnackMessage(
                        'Error while loading required information to display this page',
                    );
                }
            }
        };

        // if we have a stripe account, we need to fetch the status
        if (hasIntegratedStripe) {
            fetchStripeAccountStatus();
        }
    }, [hasIntegratedStripe, organization]);

    useEffect(() => {
        if (tabValue === 6 && organization) {
            push(`/networking/${organization.uuid}`);
        }
    }, [push, tabValue, organization]);

    return (
        <TopBarWithShareIconLayout
            onBack={() => goBack()}
            onShare={() => toggleSharing()}
            title={`${organization?.name}  - Organization Settings` || ''}
            right={
                <div style={{ display: 'flex' }}>
                    <SharedUsersAvatarGroup sharedUsers={sharedUsers} />
                </div>
            }
        >
            <Grid container direction={isSmallScreen ? 'column' : 'row'} justifyContent="center">
                <Tabs
                    sx={{ my: 8, flexGrow: 1 }}
                    value={tabValue}
                    onChange={handleTabValueChange}
                    orientation={isSmallScreen ? 'vertical' : 'horizontal'}
                    centered
                    indicatorColor="primary"
                >
                    <Tab
                        label="Organization Details"
                        sx={{
                            flexGrow: 1,
                            border: isSmallScreen ? '1px solid' : 'none',
                            borderRadius: 0,
                            margin: isSmallScreen ? '5px' : '0',
                            maxWidth: isSmallScreen ? 'none' : 'initial',
                            backgroundColor:
                                isSmallScreen && tabValue === 0 ? 'primary.main' : 'none',
                            color: isSmallScreen && tabValue === 0 ? '#fff !important' : 'initial',
                        }}
                    />
                    <Tab
                        label="Third Party Apps"
                        sx={{
                            flexGrow: 1,
                            border: isSmallScreen ? '1px solid' : 'none',
                            borderRadius: 0,
                            margin: isSmallScreen ? '5px' : '0',
                            maxWidth: isSmallScreen ? 'none' : 'initial',
                            backgroundColor:
                                isSmallScreen && tabValue === 1 ? 'primary.main' : 'none',
                            color: isSmallScreen && tabValue === 1 ? '#fff !important' : 'initial',
                        }}
                    />
                    <Tab
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <WorkspacePremiumIcon style={{ marginRight: '4px' }} />{' '}
                                {/* Icon before the label */}
                                Premium Features
                                {/* <Icon style={{ marginLeft: '4px' }} /> */}{' '}
                                {/* Icon after the label */}
                            </div>
                        }
                        sx={{
                            flexGrow: 1,
                            border: isSmallScreen ? '1px solid' : 'none',
                            borderRadius: 0,
                            margin: isSmallScreen ? '5px' : '0',
                            maxWidth: isSmallScreen ? 'none' : 'initial',
                            backgroundColor:
                                isSmallScreen && tabValue === 2 ? 'primary.main' : 'none',
                            color: isSmallScreen && tabValue === 2 ? '#fff !important' : '#3F4953',
                        }}
                    />
                    <Tab
                        label="SQL Access"
                        sx={{
                            flexGrow: 1,
                            border: isSmallScreen ? '1px solid' : 'none',
                            borderRadius: 0,
                            margin: isSmallScreen ? '5px' : '0',
                            maxWidth: isSmallScreen ? 'none' : 'initial',
                            backgroundColor:
                                isSmallScreen && tabValue === 3 ? 'primary.main' : 'none',
                            color: isSmallScreen && tabValue === 3 ? '#fff !important' : 'initial',
                        }}
                    />
                    <Tab
                        label={
                            hasStripe ? (
                                'Marketplace'
                            ) : (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {'Setup '}
                                    <StripeIcon
                                        style={{
                                            marginRight: '4px',
                                            marginLeft: '4px',
                                            width: '45px',
                                            height: '40px',
                                        }}
                                    />
                                </div>
                            )
                        }
                        sx={{
                            flexGrow: 1,
                            border: isSmallScreen ? '1px solid' : 'none',
                            borderRadius: 0,
                            margin: isSmallScreen ? '5px' : '0',
                            maxWidth: isSmallScreen ? 'none' : 'initial',
                            backgroundColor:
                                isSmallScreen && tabValue === 4 ? 'primary.main' : 'none',
                            color: isSmallScreen && tabValue === 4 ? '#fff !important' : 'initial',
                        }}
                    />
                    <Tab
                        label={
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <PlaceIcon style={{ marginRight: '8px' }} />
                                Pods
                            </div>
                        }
                        sx={{
                            flexGrow: 1,
                            border: isSmallScreen ? '1px solid' : 'none',
                            borderRadius: 0,
                            margin: isSmallScreen ? '5px' : '0',
                            maxWidth: isSmallScreen ? 'none' : 'initial',
                            backgroundColor:
                                isSmallScreen && tabValue === 5 ? 'primary.main' : 'none',
                            color: isSmallScreen && tabValue === 5 ? '#fff !important' : 'initial',
                        }}
                    />
                    <Tab
                        label={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <OfficeDeskIcon
                                    style={{ marginRight: '4px', width: '20px', height: '20px' }}
                                />
                                {/* Icon replacement */}Back Office
                                {/* <Icon style={{ marginLeft: '4px' }} /> */}{' '}
                            </div>
                        }
                        sx={{
                            flexGrow: 1,
                            border: isSmallScreen ? '1px solid' : 'none',
                            borderRadius: 0,
                            margin: isSmallScreen ? '5px' : '0',
                            maxWidth: isSmallScreen ? 'none' : 'initial',
                            backgroundColor:
                                isSmallScreen && tabValue === 6 ? 'primary.main' : 'none',
                            color: isSmallScreen && tabValue === 6 ? '#fff !important' : '#3F4953',
                        }}
                    />
                </Tabs>
            </Grid>
            {tabValue === 0 && (
                <Grid
                    container
                    spacing={4}
                    sx={(theme) => ({
                        width: '100%',
                        maxWidth: theme.breakpoints.values.lg,
                        margin: '0 auto', // Center the grid
                        [theme.breakpoints.down('lg')]: {
                            maxWidth: '100%',
                        },
                    })}
                >
                    {organization && (
                        <React.Fragment>
                            <Grid item xs={12} lg={6}>
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginTop: theme.spacing(6),
                                    }}
                                >
                                    <Typography
                                        sx={{ textAlign: 'center', padding: 3 }}
                                        fontWeight="bold"
                                    >
                                        Make sure your organization name is up to date.
                                    </Typography>
                                    <TextField
                                        label="Organization Name"
                                        id="name"
                                        name="name"
                                        variant="outlined"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        sx={{
                                            width: '98%',
                                            margin: theme.spacing(6, 3),
                                        }}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginTop: theme.spacing(6),
                                    }}
                                >
                                    <Typography
                                        sx={{ textAlign: 'center', padding: 3 }}
                                        fontWeight="bold"
                                    >
                                        Let others know where you are located to help build your
                                        community
                                    </Typography>
                                    <TextField
                                        label="Zip Code"
                                        id="zip"
                                        name="zip"
                                        variant="outlined"
                                        value={zip}
                                        onChange={(e) => setZip(e.target.value)}
                                        sx={{
                                            width: '98%',
                                            margin: theme.spacing(6, 3),
                                        }}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginTop: theme.spacing(6),
                                    }}
                                >
                                    <Typography
                                        sx={{ textAlign: 'center', padding: 3 }}
                                        fontWeight="bold"
                                    >
                                        Customize your 🔗 Links by changing your URL below
                                    </Typography>
                                    <TextField
                                        label="Custom Organization URL"
                                        id="slug"
                                        name="slug"
                                        variant="outlined"
                                        value={slug}
                                        onChange={(e) => setSlug(e.target.value)}
                                        sx={{
                                            width: '98%',
                                            margin: theme.spacing(6, 3),
                                        }}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginTop: theme.spacing(6),
                                        background:
                                            'linear-gradient(to bottom, white 30%, #EDF1F8 70%)',
                                    }}
                                >
                                    <Typography
                                        sx={{ textAlign: 'center', padding: 3 }}
                                        fontWeight="bold"
                                    >
                                        Send this 🔗 Link to new athletes to get started 🚀
                                    </Typography>
                                    <TextField
                                        label="Athlete Get Started URL"
                                        variant="outlined"
                                        value={athleteGetStartedUrl}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip
                                                        title={copySuccess ? 'Copied!' : 'Copy URL'}
                                                    >
                                                        <IconButton
                                                            aria-label="copy get started url"
                                                            onClick={() => copyToClipboard('start')}
                                                            edge="end"
                                                        >
                                                            <ContentCopyIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            width: '98%',
                                            margin: theme.spacing(6, 3),
                                        }}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginTop: theme.spacing(6),
                                    }}
                                >
                                    <Typography
                                        sx={{ textAlign: 'center', padding: 3 }}
                                        fontWeight="bold"
                                    >
                                        Send this 🔗 Link to existing athletes to purchase from your
                                        Marketplace
                                    </Typography>
                                    <TextField
                                        label="Athlete Marketplace URL"
                                        variant="outlined"
                                        value={athleteMarketplaceUrl}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip
                                                        title={copySuccess ? 'Copied!' : 'Copy URL'}
                                                    >
                                                        <IconButton
                                                            aria-label="copy marketplace url"
                                                            onClick={() =>
                                                                copyToClipboard('athlete')
                                                            }
                                                            edge="end"
                                                        >
                                                            <ContentCopyIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            width: '98%',
                                            margin: theme.spacing(6, 3),
                                        }}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginTop: theme.spacing(6),
                                    }}
                                >
                                    <Typography
                                        sx={{ textAlign: 'center', padding: 3 }}
                                        fontWeight="bold"
                                    >
                                        Send this 🔗 Link to your downline coaches to purchase from
                                        your Marketplace
                                    </Typography>
                                    <TextField
                                        label="Organization Marketplace URL"
                                        variant="outlined"
                                        value={orgMarketplaceUrl}
                                        InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Tooltip
                                                        title={copySuccess ? 'Copied!' : 'Copy URL'}
                                                    >
                                                        <IconButton
                                                            aria-label="copy marketplace url"
                                                            onClick={() =>
                                                                copyToClipboard('organization')
                                                            }
                                                            edge="end"
                                                        >
                                                            <ContentCopyIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            width: '98%',
                                            margin: theme.spacing(6, 3),
                                        }}
                                    />
                                </Paper>
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginTop: theme.spacing(6),
                                        minHeight: '210px',
                                    }}
                                >
                                    <Typography
                                        sx={{ textAlign: 'center', padding: 3 }}
                                        fontWeight="bold"
                                    >
                                        Write a brief description of your specialites and background
                                        so athletes and other coaches can get to know you better.
                                    </Typography>
                                    <TextField
                                        multiline
                                        rows={4}
                                        label="About Us"
                                        id="about_us"
                                        name="about_us"
                                        variant="outlined"
                                        value={aboutUs}
                                        onChange={(e) => setAboutUs(e.target.value)}
                                        sx={{
                                            width: '98%',
                                            margin: theme.spacing(6, 3),
                                        }}
                                    />
                                </Paper>
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginTop: theme.spacing(6),
                                        minHeight: '210px',
                                    }}
                                >
                                    <Typography
                                        sx={{ textAlign: 'center', padding: 3 }}
                                        fontWeight="bold"
                                    >
                                        Make sure your logo is up to date. Click ⬇️ on the logo to
                                        update.
                                    </Typography>
                                    <UploadLogo
                                        loading={logoUploading}
                                        button={false}
                                        deleteLogo={deleteLogo}
                                        uploadImage={uploadLogo}
                                        setUploadImage={setUploadLogo}
                                        defaultImgSrc={organization.logo_url}
                                        onMessage={setSnackMessage}
                                    />
                                </Paper>
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        minHeight: '240px',
                                        marginTop: theme.spacing(6),
                                    }}
                                >
                                    <Typography
                                        sx={{ textAlign: 'center', padding: 3 }}
                                        fontWeight="bold"
                                    >
                                        {organization.banner_url
                                            ? 'Your Marketplace Background Image'
                                            : 'Add a banner background to customize your marketplace'}
                                    </Typography>
                                    <UploadBanner
                                        loading={bannerUploading}
                                        button={false}
                                        deleteBanner={deleteBanner}
                                        uploadImage={uploadBanner}
                                        setUploadImage={setUploadBanner}
                                        defaultImgSrc={organization.banner_url}
                                        onMessage={setSnackMessage}
                                    />
                                </Paper>
                            </Grid>

                            <Grid item xs={12} lg={6}>
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        marginTop: theme.spacing(6),
                                        minHeight: '240px',
                                    }}
                                >
                                    <Typography
                                        sx={{ textAlign: 'center', padding: 3 }}
                                        fontWeight="bold"
                                    >
                                        Your Marketplace Intro Video
                                    </Typography>
                                    <UploadVideo
                                        loading={videoUploading}
                                        button={false}
                                        deleteVideo={deleteVideo}
                                        uploadVideo={uploadVideo}
                                        playbackId={muxPlaybackId}
                                        setUploadVideo={setUploadVideo}
                                        onMessage={setSnackMessage}
                                    />
                                </Paper>
                            </Grid>

                            <FormControl>
                                <FormLabel>
                                    <RadioGroup onChange={setFav}>
                                        <FormControlLabel
                                            checked={
                                                `${organization?.name}${organization?.uuid}` ===
                                                favOrg
                                            }
                                            control={<Radio />}
                                            label={'Set as my Primary Organization'}
                                        />
                                    </RadioGroup>
                                </FormLabel>
                            </FormControl>
                            <Hidden smDown>
                                <SaveButton />
                            </Hidden>

                            <Hidden smUp>
                                <SaveButton />
                            </Hidden>
                        </React.Fragment>
                    )}
                </Grid>
            )}
            {tabValue === 1 && (
                <Container maxWidth={false} sx={{ width: '98%', mx: 'auto' }}>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={2} sm={4} md={4}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardMedia
                                    sx={{
                                        p: 6,
                                        objectFit: 'contain',
                                    }}
                                    height={180}
                                    component="img"
                                    image={TemLogo}
                                    alt="1080 motion logo"
                                />
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary">
                                        Connect 1080 Motion to analyze your data
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    {!integrations.find((i) => i.api_type === 'tem') ? (
                                        <Button
                                            size="small"
                                            onClick={() => {
                                                setTemModal(true);
                                            }}
                                        >
                                            Connect
                                        </Button>
                                    ) : (
                                        <Button disabled size="small">
                                            Connected
                                        </Button>
                                    )}
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>

                    <Dialog open={temModal} onClose={() => setTemModal(false)}>
                        <Formik
                            onSubmit={(values) => {
                                if (!organization?.uuid) return;
                                setCreating(true);
                                createIntegration(organization.uuid, {
                                    api_key: values.apiKey,
                                    api_type: 'tem',
                                })
                                    .then((response) => {
                                        setTemModal(false);
                                        setIntegrations((i) => [...i, response.data]);
                                    })
                                    .catch(() => {})
                                    .finally(() => {
                                        setCreating(false);
                                    });
                            }}
                            validationSchema={yup.object({
                                apiKey: yup
                                    .string()
                                    .default('')
                                    .length(36, 'API key is invalid')
                                    .required('API Key is required'),
                            })}
                            initialValues={{ apiKey: '' }}
                        >
                            {({ handleChange, values, errors, touched }) => (
                                <Form>
                                    <DialogTitle>Add 1080 Motion API Key</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>
                                            Contact your 1080 Motion representative to get your API
                                            Key. Once you enter your API Key, you will be able to
                                            import your 1080 Motion data to your 1Tul account.{' '}
                                            {errors.apiKey}
                                        </DialogContentText>
                                        <TextField
                                            error={touched.apiKey && !!errors.apiKey}
                                            value={values.apiKey}
                                            name={'apiKey'}
                                            helperText={errors.apiKey as string}
                                            onChange={handleChange}
                                            autoFocus
                                            id="name"
                                            label="API Key"
                                            type="text"
                                            fullWidth
                                            variant="standard"
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => setTemModal(false)}>Cancel</Button>
                                        <LoadingButton
                                            loadingIndicator="Saving..."
                                            variant="contained"
                                            loading={creating}
                                            disabled={!!errors.apiKey}
                                            type={'submit'}
                                        >
                                            Submit
                                        </LoadingButton>
                                    </DialogActions>
                                </Form>
                            )}
                        </Formik>
                    </Dialog>
                </Container>
            )}
            {tabValue === 2 && (
                <Container maxWidth={false} sx={{ width: '98%', mx: 'auto' }}>
                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {organization && (
                            <Grid item xs={2} sm={4} md={4}>
                                {!isSubscribedToOrgAccess(organization.uuid, subscriptions) ? (
                                    <Card sx={{ maxWidth: 345 }}>
                                        <CardMedia
                                            sx={{
                                                p: 6,
                                                objectFit: 'contain',
                                            }}
                                            height={180}
                                            component="img"
                                            image={SprintAnalysisLogo}
                                            alt="Sprint Analysis Logo"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="text.secondary">
                                                Purchase Video AI - Sprint Analysis
                                            </Typography>
                                        </CardContent>
                                        {organization && (
                                            <CardActions>
                                                {!isSubscribedToVideoAnalysis(
                                                    organization.uuid,
                                                    subscriptions,
                                                ) ? (
                                                    <Button
                                                        disabled={checkingOut}
                                                        onClick={() => {
                                                            setCheckingOut(true);
                                                            createCheckoutSession(
                                                                window.location.href,
                                                                organization?.uuid,
                                                                process.env
                                                                    .REACT_APP_STRIPE_VIDEO_PRICE,
                                                            )
                                                                .then((response) => {
                                                                    window.location.href =
                                                                        response.data.url;
                                                                })
                                                                .catch(() => {
                                                                    setCheckingOut(false);
                                                                });
                                                        }}
                                                    >
                                                        {' '}
                                                        <CreditCardIcon sx={{ mr: 4 }} />
                                                        Purchase Video Analysis - Sprint
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        onClick={() => {
                                                            setCheckingOut(true);
                                                            createCheckoutSession(
                                                                window.location.href,
                                                                organization?.uuid,
                                                                process.env
                                                                    .REACT_APP_STRIPE_VIDEO_PRICE,
                                                            )
                                                                .then((response) => {
                                                                    window.location.href =
                                                                        response.data.url;
                                                                })
                                                                .catch(() => {
                                                                    setCheckingOut(false);
                                                                });
                                                        }}
                                                        size="small"
                                                    >
                                                        Manage Subscription
                                                    </Button>
                                                )}
                                            </CardActions>
                                        )}
                                    </Card>
                                ) : (
                                    <Card sx={{ maxWidth: 345 }}>
                                        <CardMedia
                                            sx={{
                                                p: 6,
                                                objectFit: 'contain',
                                            }}
                                            height={180}
                                            component="img"
                                            image={SprintAnalysisLogo}
                                            alt="Sprint Analysis Logo"
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="text.secondary">
                                                Video AI - Sprint Analysis
                                            </Typography>
                                        </CardContent>
                                        {organization && (
                                            <CardActions>
                                                <Button disabled={true}>
                                                    Included in Organization Subscription
                                                </Button>
                                            </CardActions>
                                        )}
                                    </Card>
                                )}
                            </Grid>
                        )}
                        <Grid item xs={2} sm={4} md={4}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardMedia
                                    sx={{
                                        p: 6,
                                        objectFit: 'contain',
                                    }}
                                    height={180}
                                    component="img"
                                    image={DbImage}
                                    alt="Sql Access"
                                />
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary">
                                        Purchase SQL Access for all your BI tools
                                    </Typography>
                                </CardContent>
                                {organization && (
                                    <CardActions>
                                        {!isSubscribedToSqlAccess(
                                            organization.uuid,
                                            subscriptions,
                                        ) ? (
                                            <Button
                                                disabled={checkingOut}
                                                onClick={() => {
                                                    setCheckingOut(true);
                                                    createCheckoutSession(
                                                        window.location.href,
                                                        organization?.uuid,
                                                        process.env.REACT_APP_STRIPE_SQL_PRICE,
                                                    )
                                                        .then((response) => {
                                                            window.location.href =
                                                                response.data.url;
                                                        })
                                                        .catch(() => {
                                                            setCheckingOut(false);
                                                        });
                                                }}
                                            >
                                                {' '}
                                                <CreditCardIcon sx={{ mr: 4 }} />
                                                Purchase SQL Access
                                            </Button>
                                        ) : (
                                            <>
                                                <Button
                                                    onClick={() => {
                                                        setCheckingOut(true);
                                                        createCheckoutSession(
                                                            window.location.href,
                                                            organization?.uuid,
                                                            process.env.REACT_APP_STRIPE_SQL_PRICE,
                                                        )
                                                            .then((response) => {
                                                                window.location.href =
                                                                    response.data.url;
                                                            })
                                                            .catch(() => {
                                                                setCheckingOut(false);
                                                            });
                                                    }}
                                                    size="small"
                                                >
                                                    Manage Subscription
                                                </Button>
                                                <Button
                                                    onClick={() => {
                                                        setTabValue(3);
                                                    }}
                                                    size="small"
                                                >
                                                    SQL Users
                                                </Button>
                                            </>
                                        )}
                                    </CardActions>
                                )}
                            </Card>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Card sx={{ maxWidth: 345 }}>
                                <CardMedia
                                    sx={{
                                        p: 6,
                                        objectFit: 'contain',
                                    }}
                                    height={180}
                                    component="img"
                                    image={SpaceShuttle}
                                    alt="Organization Subscription"
                                />
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary">
                                        Organization Subscription
                                    </Typography>
                                </CardContent>
                                {organization && (
                                    <CardActions>
                                        {!isSubscribedToOrgAccess(
                                            organization.uuid,
                                            subscriptions,
                                        ) ? (
                                            <ChooseOrganizationPlanPopover
                                                disabled={checkingOut}
                                                planClicked={(value) => {
                                                    setCheckingOut(true);
                                                    createCheckoutSession(
                                                        window.location.href,
                                                        organization?.uuid,
                                                        value,
                                                    )
                                                        .then((response) => {
                                                            window.location.href =
                                                                response.data.url;
                                                        })
                                                        .catch(() => {
                                                            setCheckingOut(false);
                                                        });
                                                }}
                                            />
                                        ) : (
                                            <Stack spacing={1} direction={'row'}>
                                                <Button
                                                    variant={'outlined'}
                                                    style={{ fontSize: '0.7rem' }}
                                                    onClick={() => {
                                                        setCheckingOut(true);
                                                        createCheckoutSession(
                                                            window.location.href,
                                                            organization?.uuid,
                                                            orgSubscription?.stripe_price,
                                                        )
                                                            .then((response) => {
                                                                window.location.href =
                                                                    response.data.url;
                                                            })
                                                            .catch(() => {
                                                                setCheckingOut(false);
                                                            });
                                                    }}
                                                    size="small"
                                                >
                                                    Manage Org Subscription
                                                </Button>
                                                <Button
                                                    variant={'outlined'}
                                                    style={{ fontSize: '0.7rem' }}
                                                    onClick={() => {
                                                        setCheckingOut(true);
                                                        viewStripeHistory(
                                                            window.location.href,
                                                            organization?.uuid,
                                                        )
                                                            .then((response) => {
                                                                window.location.href =
                                                                    response.data.url;
                                                            })
                                                            .catch(() => {
                                                                setCheckingOut(false);
                                                            });
                                                    }}
                                                    size="small"
                                                >
                                                    View Billing History
                                                </Button>
                                                <Button
                                                    variant={'outlined'}
                                                    style={{ fontSize: '0.7rem' }}
                                                    onClick={() => {
                                                        setCheckingOut(true);
                                                        createCheckoutSession(
                                                            window.location.href,
                                                            organization?.uuid,
                                                            orgSubscription?.stripe_price,
                                                            'subscription_cancel',
                                                        )
                                                            .then((response) => {
                                                                window.location.href =
                                                                    response.data.url;
                                                            })
                                                            .catch(() => {
                                                                setCheckingOut(false);
                                                            });
                                                    }}
                                                    size="small"
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    variant={'outlined'}
                                                    style={{ fontSize: '0.7rem' }}
                                                    onClick={() => {
                                                        setCheckingOut(true);
                                                        createCheckoutSession(
                                                            window.location.href,
                                                            organization?.uuid,
                                                            process.env
                                                                .REACT_APP_STRIPE_ORGANIZATION_LEVEL_3_PRICE,
                                                            'subscription_change_card',
                                                        )
                                                            .then((response) => {
                                                                window.location.href =
                                                                    response.data.url;
                                                            })
                                                            .catch(() => {
                                                                setCheckingOut(false);
                                                            });
                                                    }}
                                                    size="small"
                                                >
                                                    Change Card
                                                </Button>
                                            </Stack>
                                        )}
                                    </CardActions>
                                )}
                            </Card>
                        </Grid>
                    </Grid>

                    <Dialog
                        open={sprintAnalysisModal}
                        onClose={() => setSprintAnalysisModal(false)}
                    >
                        <DialogTitle>Video AI - Sprint Analysis</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Video must be shot in 60 frames per second.
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </Container>
            )}
            {tabValue === 3 && (
                <Container maxWidth={false} sx={{ width: '98%', mx: 'auto' }}>
                    {organization && <SqlUsersList organization={organization} />}
                </Container>
            )}
            {tabValue === 4 && (
                <Container maxWidth={false} sx={{ width: '98%', mx: 'auto' }}>
                    {organization && <MarketplaceSettingsPage organization={organization} />}
                </Container>
            )}
            {tabValue === 5 && (
                <Container maxWidth={false} sx={{ width: '98%', mx: 'auto' }}>
                    {organization && <PodsPage organization={organization} />}
                </Container>
            )}
            {tabValue === 6 && (
                <Container maxWidth={false} sx={{ width: '98%', mx: 'auto' }}>
                    {organization && <CircularProgress />}
                </Container>
            )}
            {organization && sharingOpen && (
                <OrganizationSharingDrawer
                    onClose={toggleSharing}
                    open={sharingOpen}
                    organization={organization}
                    onUsersChanged={setSharedUsers}
                />
            )}

            <SnackBar
                open={notifyUserMessage ? true : false}
                message={notifyUserMessage}
                setOpen={() => {
                    setNotifyUserMessage('');
                }}
                severity={success ? 'success' : 'error'}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={!!snackMessage}
                message={snackMessage ?? ''}
                autoHideDuration={5000}
                onClose={() => setSnackMessage(null)}
            />
        </TopBarWithShareIconLayout>
    );
}
